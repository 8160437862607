


.App {
  text-align: center;
  font-size: 14px;
}

.App-logo {
  height: 40px;
  margin-top: 15px;
margin-left:20px;  

}

.Card {
  max-width: 100%;
  max-height: 100%;
  border: 5px grey;
}

.App-header {
  background-color: #ffff;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
