/* autoprefixer: off */
/**
     * Grid containing element
     */
     .gel-wrap {
        max-width: 1008px;
        margin: 0 auto;
        padding-right: 8px;
        padding-left: 8px;
      }
      
      @media (min-width: 25em) {
        .gel-wrap {
          padding-left: 16px;
          padding-right: 16px;
        }
      }
      
      @media (min-width: 80em) {
        .gel-wrap {
          max-width: 1280px;
        }
      }
      
      /**
           * A grid row
           */
      .gel-layout {
        list-style: none;
        direction: ltr;
        text-align: left;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-flex-grow: 1;
        -ms-flex-grow: 1;
        flex-grow: 1;
        margin-right: 0;
        margin-left: -8px;
        padding-right: 0;
        padding-left: 0;
        letter-spacing: -0.31em;
        text-rendering: optimizespeed;
      }
      
      @media (min-width: 37.5em) {
        .gel-layout {
          margin-left: -16px;
        }
      }
      
      .opera-only :-o-prefocus,
      .gel-layout {
        word-spacing: -0.43em;
      }
      
      /**
           * A single grid item
           */
      .gel-layout__item {
        width: 100%;
        display: inline-block;
        padding-left: 8px;
        text-align: left;
        vertical-align: top;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        letter-spacing: normal;
        word-spacing: normal;
        text-rendering: auto;
      }
      
      @media (min-width: 37.5em) {
        .gel-layout__item {
          padding-left: 16px;
        }
      }
      
      /**
           * Layouts with no gutters.
           */
      .gel-layout--flush {
        margin-left: 0;
      }
      
      .gel-layout--flush > .gel-layout__item {
        padding-left: 0;
      }
      
      /**
           * Reversed rendered order of layout items, e.g. items 1, 2, 3, 4 in your
           * markup will display in order 4, 3, 2, 1 on your page
           */
      .gel-layout--rev {
        -webkit-flex-direction: row-reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
      }
      
      .no-flexbox .gel-layout--rev {
        direction: rtl;
        text-align: left;
      }
      
      .no-flexbox .gel-layout--rev > .gel-layout__item {
        direction: ltr;
        text-align: left;
      }
      
      /**
           * Align layout items to the vertical centers of each other
           */
      .gel-layout--middle {
        -webkit-align-items: center;
        -ms-flex-align: center;
        -ms-grid-row-align: center;
        align-items: center;
      }
      
      .gel-layout--middle > .gel-layout__item {
        vertical-align: middle;
      }
      
      /**
           * Align layout items to the vertical bottoms of each other
           */
      .gel-layout--bottom {
        -webkit-align-items: flex-end;
        -ms-flex-align: end;
        -ms-grid-row-align: flex-end;
        align-items: flex-end;
      }
      
      .gel-layout--bottom > .gel-layout__item {
        vertical-align: bottom;
      }
      
      /**
           * Make the layout items fill up from the right hand side
           */
      .gel-layout--right {
        text-align: right;
        -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
        justify-content: flex-end;
      }
      
      .gel-layout--right > .gel-layout__item {
        text-align: left;
      }
      
      /**
           * Make the layout items fill up from the center outward
           */
      .gel-layout--center {
        text-align: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
      }
      
      .gel-layout--center > .gel-layout__item {
        text-align: left;
      }
      
      /**
           * Cause layout items to take up a non-explicit amount of width
           */
      .gel-layout--auto > .gel-layout__item {
        width: auto;
      }
      
      /**
           * Disable the flexbox grid
           */
      .gel-layout--no-flex,
      .gel-layout--no-flex > .gel-layout__item {
        display: block;
        display: inline-block;
      }
      
      /**
               * Force items to be of equal height
               */
      .gel-layout--equal > .gel-layout__item {
        display: -webkit-flex;
        display: flex;
      }
      
      /**
               * Allow items to devide the space equally between the number of items
               */
      .gel-layout--fit > .gel-layout__item {
        width: auto;
        -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
      }
      
      /**
               * Align a single grid item to the top
               */
      .gel-layout__item--top {
        -webkit-align-self: flex-start;
        -ms-flex-item-align: start;
        align-self: flex-start;
      }
      
      /**
               * Align a single grid item to the center
               */
      .gel-layout__item--center {
        -webkit-align-self: center;
        -ms-flex-item-align: center;
        align-self: center;
      }
      
      /**
               * Align a single grid item to the bottom
               */
      .gel-layout__item--bottom {
        -webkit-align-self: flex-end;
        -ms-flex-item-align: end;
        align-self: flex-end;
      }
      
      .gel-1\/1 {
        width: 100% !important;
      }
      
      .gel-1\/2 {
        width: 50% !important;
      }
      
      .gel-1\/3 {
        width: 33.33333% !important;
      }
      
      .gel-2\/3 {
        width: 66.66667% !important;
      }
      
      .gel-1\/4 {
        width: 25% !important;
      }
      
      .gel-2\/4 {
        width: 50% !important;
      }
      
      .gel-3\/4 {
        width: 75% !important;
      }
      
      .gel-1\/5 {
        width: 20% !important;
      }
      
      .gel-2\/5 {
        width: 40% !important;
      }
      
      .gel-3\/5 {
        width: 60% !important;
      }
      
      .gel-4\/5 {
        width: 80% !important;
      }
      
      .gel-1\/8 {
        width: 12.5% !important;
      }
      
      .gel-2\/8 {
        width: 25% !important;
      }
      
      .gel-3\/8 {
        width: 37.5% !important;
      }
      
      .gel-4\/8 {
        width: 50% !important;
      }
      
      .gel-5\/8 {
        width: 62.5% !important;
      }
      
      .gel-6\/8 {
        width: 75% !important;
      }
      
      .gel-7\/8 {
        width: 87.5% !important;
      }
      
      .gel-1\/10 {
        width: 10% !important;
      }
      
      .gel-2\/10 {
        width: 20% !important;
      }
      
      .gel-3\/10 {
        width: 30% !important;
      }
      
      .gel-4\/10 {
        width: 40% !important;
      }
      
      .gel-5\/10 {
        width: 50% !important;
      }
      
      .gel-6\/10 {
        width: 60% !important;
      }
      
      .gel-7\/10 {
        width: 70% !important;
      }
      
      .gel-8\/10 {
        width: 80% !important;
      }
      
      .gel-9\/10 {
        width: 90% !important;
      }
      
      .gel-1\/12 {
        width: 8.33333% !important;
      }
      
      .gel-2\/12 {
        width: 16.66667% !important;
      }
      
      .gel-3\/12 {
        width: 25% !important;
      }
      
      .gel-4\/12 {
        width: 33.33333% !important;
      }
      
      .gel-5\/12 {
        width: 41.66667% !important;
      }
      
      .gel-6\/12 {
        width: 50% !important;
      }
      
      .gel-7\/12 {
        width: 58.33333% !important;
      }
      
      .gel-8\/12 {
        width: 66.66667% !important;
      }
      
      .gel-9\/12 {
        width: 75% !important;
      }
      
      .gel-10\/12 {
        width: 83.33333% !important;
      }
      
      .gel-11\/12 {
        width: 91.66667% !important;
      }
      
      .gel-1\/24 {
        width: 4.16667% !important;
      }
      
      .gel-2\/24 {
        width: 8.33333% !important;
      }
      
      .gel-3\/24 {
        width: 12.5% !important;
      }
      
      .gel-4\/24 {
        width: 16.66667% !important;
      }
      
      .gel-5\/24 {
        width: 20.83333% !important;
      }
      
      .gel-6\/24 {
        width: 25% !important;
      }
      
      .gel-7\/24 {
        width: 29.16667% !important;
      }
      
      .gel-8\/24 {
        width: 33.33333% !important;
      }
      
      .gel-9\/24 {
        width: 37.5% !important;
      }
      
      .gel-10\/24 {
        width: 41.66667% !important;
      }
      
      .gel-11\/24 {
        width: 45.83333% !important;
      }
      
      .gel-12\/24 {
        width: 50% !important;
      }
      
      .gel-13\/24 {
        width: 54.16667% !important;
      }
      
      .gel-14\/24 {
        width: 58.33333% !important;
      }
      
      .gel-15\/24 {
        width: 62.5% !important;
      }
      
      .gel-16\/24 {
        width: 66.66667% !important;
      }
      
      .gel-17\/24 {
        width: 70.83333% !important;
      }
      
      .gel-18\/24 {
        width: 75% !important;
      }
      
      .gel-19\/24 {
        width: 79.16667% !important;
      }
      
      .gel-20\/24 {
        width: 83.33333% !important;
      }
      
      .gel-21\/24 {
        width: 87.5% !important;
      }
      
      .gel-22\/24 {
        width: 91.66667% !important;
      }
      
      .gel-23\/24 {
        width: 95.83333% !important;
      }
      
      @media (min-width: 25em) {
        .gel-1\/1\@s {
          width: 100% !important;
        }
        .gel-1\/2\@s {
          width: 50% !important;
        }
        .gel-1\/3\@s {
          width: 33.33333% !important;
        }
        .gel-2\/3\@s {
          width: 66.66667% !important;
        }
        .gel-1\/4\@s {
          width: 25% !important;
        }
        .gel-2\/4\@s {
          width: 50% !important;
        }
        .gel-3\/4\@s {
          width: 75% !important;
        }
        .gel-1\/5\@s {
          width: 20% !important;
        }
        .gel-2\/5\@s {
          width: 40% !important;
        }
        .gel-3\/5\@s {
          width: 60% !important;
        }
        .gel-4\/5\@s {
          width: 80% !important;
        }
        .gel-1\/8\@s {
          width: 12.5% !important;
        }
        .gel-2\/8\@s {
          width: 25% !important;
        }
        .gel-3\/8\@s {
          width: 37.5% !important;
        }
        .gel-4\/8\@s {
          width: 50% !important;
        }
        .gel-5\/8\@s {
          width: 62.5% !important;
        }
        .gel-6\/8\@s {
          width: 75% !important;
        }
        .gel-7\/8\@s {
          width: 87.5% !important;
        }
        .gel-1\/10\@s {
          width: 10% !important;
        }
        .gel-2\/10\@s {
          width: 20% !important;
        }
        .gel-3\/10\@s {
          width: 30% !important;
        }
        .gel-4\/10\@s {
          width: 40% !important;
        }
        .gel-5\/10\@s {
          width: 50% !important;
        }
        .gel-6\/10\@s {
          width: 60% !important;
        }
        .gel-7\/10\@s {
          width: 70% !important;
        }
        .gel-8\/10\@s {
          width: 80% !important;
        }
        .gel-9\/10\@s {
          width: 90% !important;
        }
        .gel-1\/12\@s {
          width: 8.33333% !important;
        }
        .gel-2\/12\@s {
          width: 16.66667% !important;
        }
        .gel-3\/12\@s {
          width: 25% !important;
        }
        .gel-4\/12\@s {
          width: 33.33333% !important;
        }
        .gel-5\/12\@s {
          width: 41.66667% !important;
        }
        .gel-6\/12\@s {
          width: 50% !important;
        }
        .gel-7\/12\@s {
          width: 58.33333% !important;
        }
        .gel-8\/12\@s {
          width: 66.66667% !important;
        }
        .gel-9\/12\@s {
          width: 75% !important;
        }
        .gel-10\/12\@s {
          width: 83.33333% !important;
        }
        .gel-11\/12\@s {
          width: 91.66667% !important;
        }
        .gel-1\/24\@s {
          width: 4.16667% !important;
        }
        .gel-2\/24\@s {
          width: 8.33333% !important;
        }
        .gel-3\/24\@s {
          width: 12.5% !important;
        }
        .gel-4\/24\@s {
          width: 16.66667% !important;
        }
        .gel-5\/24\@s {
          width: 20.83333% !important;
        }
        .gel-6\/24\@s {
          width: 25% !important;
        }
        .gel-7\/24\@s {
          width: 29.16667% !important;
        }
        .gel-8\/24\@s {
          width: 33.33333% !important;
        }
        .gel-9\/24\@s {
          width: 37.5% !important;
        }
        .gel-10\/24\@s {
          width: 41.66667% !important;
        }
        .gel-11\/24\@s {
          width: 45.83333% !important;
        }
        .gel-12\/24\@s {
          width: 50% !important;
        }
        .gel-13\/24\@s {
          width: 54.16667% !important;
        }
        .gel-14\/24\@s {
          width: 58.33333% !important;
        }
        .gel-15\/24\@s {
          width: 62.5% !important;
        }
        .gel-16\/24\@s {
          width: 66.66667% !important;
        }
        .gel-17\/24\@s {
          width: 70.83333% !important;
        }
        .gel-18\/24\@s {
          width: 75% !important;
        }
        .gel-19\/24\@s {
          width: 79.16667% !important;
        }
        .gel-20\/24\@s {
          width: 83.33333% !important;
        }
        .gel-21\/24\@s {
          width: 87.5% !important;
        }
        .gel-22\/24\@s {
          width: 91.66667% !important;
        }
        .gel-23\/24\@s {
          width: 95.83333% !important;
        }
      }
      
      @media (min-width: 37.5em) {
        .gel-1\/1\@m {
          width: 100% !important;
        }
        .gel-1\/2\@m {
          width: 50% !important;
        }
        .gel-1\/3\@m {
          width: 33.33333% !important;
        }
        .gel-2\/3\@m {
          width: 66.66667% !important;
        }
        .gel-1\/4\@m {
          width: 25% !important;
        }
        .gel-2\/4\@m {
          width: 50% !important;
        }
        .gel-3\/4\@m {
          width: 75% !important;
        }
        .gel-1\/5\@m {
          width: 20% !important;
        }
        .gel-2\/5\@m {
          width: 40% !important;
        }
        .gel-3\/5\@m {
          width: 60% !important;
        }
        .gel-4\/5\@m {
          width: 80% !important;
        }
        .gel-1\/8\@m {
          width: 12.5% !important;
        }
        .gel-2\/8\@m {
          width: 25% !important;
        }
        .gel-3\/8\@m {
          width: 37.5% !important;
        }
        .gel-4\/8\@m {
          width: 50% !important;
        }
        .gel-5\/8\@m {
          width: 62.5% !important;
        }
        .gel-6\/8\@m {
          width: 75% !important;
        }
        .gel-7\/8\@m {
          width: 87.5% !important;
        }
        .gel-1\/10\@m {
          width: 10% !important;
        }
        .gel-2\/10\@m {
          width: 20% !important;
        }
        .gel-3\/10\@m {
          width: 30% !important;
        }
        .gel-4\/10\@m {
          width: 40% !important;
        }
        .gel-5\/10\@m {
          width: 50% !important;
        }
        .gel-6\/10\@m {
          width: 60% !important;
        }
        .gel-7\/10\@m {
          width: 70% !important;
        }
        .gel-8\/10\@m {
          width: 80% !important;
        }
        .gel-9\/10\@m {
          width: 90% !important;
        }
        .gel-1\/12\@m {
          width: 8.33333% !important;
        }
        .gel-2\/12\@m {
          width: 16.66667% !important;
        }
        .gel-3\/12\@m {
          width: 25% !important;
        }
        .gel-4\/12\@m {
          width: 33.33333% !important;
        }
        .gel-5\/12\@m {
          width: 41.66667% !important;
        }
        .gel-6\/12\@m {
          width: 50% !important;
        }
        .gel-7\/12\@m {
          width: 58.33333% !important;
        }
        .gel-8\/12\@m {
          width: 66.66667% !important;
        }
        .gel-9\/12\@m {
          width: 75% !important;
        }
        .gel-10\/12\@m {
          width: 83.33333% !important;
        }
        .gel-11\/12\@m {
          width: 91.66667% !important;
        }
        .gel-1\/24\@m {
          width: 4.16667% !important;
        }
        .gel-2\/24\@m {
          width: 8.33333% !important;
        }
        .gel-3\/24\@m {
          width: 12.5% !important;
        }
        .gel-4\/24\@m {
          width: 16.66667% !important;
        }
        .gel-5\/24\@m {
          width: 20.83333% !important;
        }
        .gel-6\/24\@m {
          width: 25% !important;
        }
        .gel-7\/24\@m {
          width: 29.16667% !important;
        }
        .gel-8\/24\@m {
          width: 33.33333% !important;
        }
        .gel-9\/24\@m {
          width: 37.5% !important;
        }
        .gel-10\/24\@m {
          width: 41.66667% !important;
        }
        .gel-11\/24\@m {
          width: 45.83333% !important;
        }
        .gel-12\/24\@m {
          width: 50% !important;
        }
        .gel-13\/24\@m {
          width: 54.16667% !important;
        }
        .gel-14\/24\@m {
          width: 58.33333% !important;
        }
        .gel-15\/24\@m {
          width: 62.5% !important;
        }
        .gel-16\/24\@m {
          width: 66.66667% !important;
        }
        .gel-17\/24\@m {
          width: 70.83333% !important;
        }
        .gel-18\/24\@m {
          width: 75% !important;
        }
        .gel-19\/24\@m {
          width: 79.16667% !important;
        }
        .gel-20\/24\@m {
          width: 83.33333% !important;
        }
        .gel-21\/24\@m {
          width: 87.5% !important;
        }
        .gel-22\/24\@m {
          width: 91.66667% !important;
        }
        .gel-23\/24\@m {
          width: 95.83333% !important;
        }
      }
      
      @media (min-width: 56.25em) {
        .gel-1\/1\@l {
          width: 100% !important;
        }
        .gel-1\/2\@l {
          width: 50% !important;
        }
        .gel-1\/3\@l {
          width: 33.33333% !important;
        }
        .gel-2\/3\@l {
          width: 66.66667% !important;
        }
        .gel-1\/4\@l {
          width: 25% !important;
        }
        .gel-2\/4\@l {
          width: 50% !important;
        }
        .gel-3\/4\@l {
          width: 75% !important;
        }
        .gel-1\/5\@l {
          width: 20% !important;
        }
        .gel-2\/5\@l {
          width: 40% !important;
        }
        .gel-3\/5\@l {
          width: 60% !important;
        }
        .gel-4\/5\@l {
          width: 80% !important;
        }
        .gel-1\/8\@l {
          width: 12.5% !important;
        }
        .gel-2\/8\@l {
          width: 25% !important;
        }
        .gel-3\/8\@l {
          width: 37.5% !important;
        }
        .gel-4\/8\@l {
          width: 50% !important;
        }
        .gel-5\/8\@l {
          width: 62.5% !important;
        }
        .gel-6\/8\@l {
          width: 75% !important;
        }
        .gel-7\/8\@l {
          width: 87.5% !important;
        }
        .gel-1\/10\@l {
          width: 10% !important;
        }
        .gel-2\/10\@l {
          width: 20% !important;
        }
        .gel-3\/10\@l {
          width: 30% !important;
        }
        .gel-4\/10\@l {
          width: 40% !important;
        }
        .gel-5\/10\@l {
          width: 50% !important;
        }
        .gel-6\/10\@l {
          width: 60% !important;
        }
        .gel-7\/10\@l {
          width: 70% !important;
        }
        .gel-8\/10\@l {
          width: 80% !important;
        }
        .gel-9\/10\@l {
          width: 90% !important;
        }
        .gel-1\/12\@l {
          width: 8.33333% !important;
        }
        .gel-2\/12\@l {
          width: 16.66667% !important;
        }
        .gel-3\/12\@l {
          width: 25% !important;
        }
        .gel-4\/12\@l {
          width: 33.33333% !important;
        }
        .gel-5\/12\@l {
          width: 41.66667% !important;
        }
        .gel-6\/12\@l {
          width: 50% !important;
        }
        .gel-7\/12\@l {
          width: 58.33333% !important;
        }
        .gel-8\/12\@l {
          width: 66.66667% !important;
        }
        .gel-9\/12\@l {
          width: 75% !important;
        }
        .gel-10\/12\@l {
          width: 83.33333% !important;
        }
        .gel-11\/12\@l {
          width: 91.66667% !important;
        }
        .gel-1\/24\@l {
          width: 4.16667% !important;
        }
        .gel-2\/24\@l {
          width: 8.33333% !important;
        }
        .gel-3\/24\@l {
          width: 12.5% !important;
        }
        .gel-4\/24\@l {
          width: 16.66667% !important;
        }
        .gel-5\/24\@l {
          width: 20.83333% !important;
        }
        .gel-6\/24\@l {
          width: 25% !important;
        }
        .gel-7\/24\@l {
          width: 29.16667% !important;
        }
        .gel-8\/24\@l {
          width: 33.33333% !important;
        }
        .gel-9\/24\@l {
          width: 37.5% !important;
        }
        .gel-10\/24\@l {
          width: 41.66667% !important;
        }
        .gel-11\/24\@l {
          width: 45.83333% !important;
        }
        .gel-12\/24\@l {
          width: 50% !important;
        }
        .gel-13\/24\@l {
          width: 54.16667% !important;
        }
        .gel-14\/24\@l {
          width: 58.33333% !important;
        }
        .gel-15\/24\@l {
          width: 62.5% !important;
        }
        .gel-16\/24\@l {
          width: 66.66667% !important;
        }
        .gel-17\/24\@l {
          width: 70.83333% !important;
        }
        .gel-18\/24\@l {
          width: 75% !important;
        }
        .gel-19\/24\@l {
          width: 79.16667% !important;
        }
        .gel-20\/24\@l {
          width: 83.33333% !important;
        }
        .gel-21\/24\@l {
          width: 87.5% !important;
        }
        .gel-22\/24\@l {
          width: 91.66667% !important;
        }
        .gel-23\/24\@l {
          width: 95.83333% !important;
        }
      }
      
      @media (min-width: 63em) {
        .gel-1\/1\@xl {
          width: 100% !important;
        }
        .gel-1\/2\@xl {
          width: 50% !important;
        }
        .gel-1\/3\@xl {
          width: 33.33333% !important;
        }
        .gel-2\/3\@xl {
          width: 66.66667% !important;
        }
        .gel-1\/4\@xl {
          width: 25% !important;
        }
        .gel-2\/4\@xl {
          width: 50% !important;
        }
        .gel-3\/4\@xl {
          width: 75% !important;
        }
        .gel-1\/5\@xl {
          width: 20% !important;
        }
        .gel-2\/5\@xl {
          width: 40% !important;
        }
        .gel-3\/5\@xl {
          width: 60% !important;
        }
        .gel-4\/5\@xl {
          width: 80% !important;
        }
        .gel-1\/8\@xl {
          width: 12.5% !important;
        }
        .gel-2\/8\@xl {
          width: 25% !important;
        }
        .gel-3\/8\@xl {
          width: 37.5% !important;
        }
        .gel-4\/8\@xl {
          width: 50% !important;
        }
        .gel-5\/8\@xl {
          width: 62.5% !important;
        }
        .gel-6\/8\@xl {
          width: 75% !important;
        }
        .gel-7\/8\@xl {
          width: 87.5% !important;
        }
        .gel-1\/10\@xl {
          width: 10% !important;
        }
        .gel-2\/10\@xl {
          width: 20% !important;
        }
        .gel-3\/10\@xl {
          width: 30% !important;
        }
        .gel-4\/10\@xl {
          width: 40% !important;
        }
        .gel-5\/10\@xl {
          width: 50% !important;
        }
        .gel-6\/10\@xl {
          width: 60% !important;
        }
        .gel-7\/10\@xl {
          width: 70% !important;
        }
        .gel-8\/10\@xl {
          width: 80% !important;
        }
        .gel-9\/10\@xl {
          width: 90% !important;
        }
        .gel-1\/12\@xl {
          width: 8.33333% !important;
        }
        .gel-2\/12\@xl {
          width: 16.66667% !important;
        }
        .gel-3\/12\@xl {
          width: 25% !important;
        }
        .gel-4\/12\@xl {
          width: 33.33333% !important;
        }
        .gel-5\/12\@xl {
          width: 41.66667% !important;
        }
        .gel-6\/12\@xl {
          width: 50% !important;
        }
        .gel-7\/12\@xl {
          width: 58.33333% !important;
        }
        .gel-8\/12\@xl {
          width: 66.66667% !important;
        }
        .gel-9\/12\@xl {
          width: 75% !important;
        }
        .gel-10\/12\@xl {
          width: 83.33333% !important;
        }
        .gel-11\/12\@xl {
          width: 91.66667% !important;
        }
        .gel-1\/24\@xl {
          width: 4.16667% !important;
        }
        .gel-2\/24\@xl {
          width: 8.33333% !important;
        }
        .gel-3\/24\@xl {
          width: 12.5% !important;
        }
        .gel-4\/24\@xl {
          width: 16.66667% !important;
        }
        .gel-5\/24\@xl {
          width: 20.83333% !important;
        }
        .gel-6\/24\@xl {
          width: 25% !important;
        }
        .gel-7\/24\@xl {
          width: 29.16667% !important;
        }
        .gel-8\/24\@xl {
          width: 33.33333% !important;
        }
        .gel-9\/24\@xl {
          width: 37.5% !important;
        }
        .gel-10\/24\@xl {
          width: 41.66667% !important;
        }
        .gel-11\/24\@xl {
          width: 45.83333% !important;
        }
        .gel-12\/24\@xl {
          width: 50% !important;
        }
        .gel-13\/24\@xl {
          width: 54.16667% !important;
        }
        .gel-14\/24\@xl {
          width: 58.33333% !important;
        }
        .gel-15\/24\@xl {
          width: 62.5% !important;
        }
        .gel-16\/24\@xl {
          width: 66.66667% !important;
        }
        .gel-17\/24\@xl {
          width: 70.83333% !important;
        }
        .gel-18\/24\@xl {
          width: 75% !important;
        }
        .gel-19\/24\@xl {
          width: 79.16667% !important;
        }
        .gel-20\/24\@xl {
          width: 83.33333% !important;
        }
        .gel-21\/24\@xl {
          width: 87.5% !important;
        }
        .gel-22\/24\@xl {
          width: 91.66667% !important;
        }
        .gel-23\/24\@xl {
          width: 95.83333% !important;
        }
      }
      
      @media (min-width: 80em) {
        .gel-1\/1\@xxl {
          width: 100% !important;
        }
        .gel-1\/2\@xxl {
          width: 50% !important;
        }
        .gel-1\/3\@xxl {
          width: 33.33333% !important;
        }
        .gel-2\/3\@xxl {
          width: 66.66667% !important;
        }
        .gel-1\/4\@xxl {
          width: 25% !important;
        }
        .gel-2\/4\@xxl {
          width: 50% !important;
        }
        .gel-3\/4\@xxl {
          width: 75% !important;
        }
        .gel-1\/5\@xxl {
          width: 20% !important;
        }
        .gel-2\/5\@xxl {
          width: 40% !important;
        }
        .gel-3\/5\@xxl {
          width: 60% !important;
        }
        .gel-4\/5\@xxl {
          width: 80% !important;
        }
        .gel-1\/8\@xxl {
          width: 12.5% !important;
        }
        .gel-2\/8\@xxl {
          width: 25% !important;
        }
        .gel-3\/8\@xxl {
          width: 37.5% !important;
        }
        .gel-4\/8\@xxl {
          width: 50% !important;
        }
        .gel-5\/8\@xxl {
          width: 62.5% !important;
        }
        .gel-6\/8\@xxl {
          width: 75% !important;
        }
        .gel-7\/8\@xxl {
          width: 87.5% !important;
        }
        .gel-1\/10\@xxl {
          width: 10% !important;
        }
        .gel-2\/10\@xxl {
          width: 20% !important;
        }
        .gel-3\/10\@xxl {
          width: 30% !important;
        }
        .gel-4\/10\@xxl {
          width: 40% !important;
        }
        .gel-5\/10\@xxl {
          width: 50% !important;
        }
        .gel-6\/10\@xxl {
          width: 60% !important;
        }
        .gel-7\/10\@xxl {
          width: 70% !important;
        }
        .gel-8\/10\@xxl {
          width: 80% !important;
        }
        .gel-9\/10\@xxl {
          width: 90% !important;
        }
        .gel-1\/12\@xxl {
          width: 8.33333% !important;
        }
        .gel-2\/12\@xxl {
          width: 16.66667% !important;
        }
        .gel-3\/12\@xxl {
          width: 25% !important;
        }
        .gel-4\/12\@xxl {
          width: 33.33333% !important;
        }
        .gel-5\/12\@xxl {
          width: 41.66667% !important;
        }
        .gel-6\/12\@xxl {
          width: 50% !important;
        }
        .gel-7\/12\@xxl {
          width: 58.33333% !important;
        }
        .gel-8\/12\@xxl {
          width: 66.66667% !important;
        }
        .gel-9\/12\@xxl {
          width: 75% !important;
        }
        .gel-10\/12\@xxl {
          width: 83.33333% !important;
        }
        .gel-11\/12\@xxl {
          width: 91.66667% !important;
        }
        .gel-1\/24\@xxl {
          width: 4.16667% !important;
        }
        .gel-2\/24\@xxl {
          width: 8.33333% !important;
        }
        .gel-3\/24\@xxl {
          width: 12.5% !important;
        }
        .gel-4\/24\@xxl {
          width: 16.66667% !important;
        }
        .gel-5\/24\@xxl {
          width: 20.83333% !important;
        }
        .gel-6\/24\@xxl {
          width: 25% !important;
        }
        .gel-7\/24\@xxl {
          width: 29.16667% !important;
        }
        .gel-8\/24\@xxl {
          width: 33.33333% !important;
        }
        .gel-9\/24\@xxl {
          width: 37.5% !important;
        }
        .gel-10\/24\@xxl {
          width: 41.66667% !important;
        }
        .gel-11\/24\@xxl {
          width: 45.83333% !important;
        }
        .gel-12\/24\@xxl {
          width: 50% !important;
        }
        .gel-13\/24\@xxl {
          width: 54.16667% !important;
        }
        .gel-14\/24\@xxl {
          width: 58.33333% !important;
        }
        .gel-15\/24\@xxl {
          width: 62.5% !important;
        }
        .gel-16\/24\@xxl {
          width: 66.66667% !important;
        }
        .gel-17\/24\@xxl {
          width: 70.83333% !important;
        }
        .gel-18\/24\@xxl {
          width: 75% !important;
        }
        .gel-19\/24\@xxl {
          width: 79.16667% !important;
        }
        .gel-20\/24\@xxl {
          width: 83.33333% !important;
        }
        .gel-21\/24\@xxl {
          width: 87.5% !important;
        }
        .gel-22\/24\@xxl {
          width: 91.66667% !important;
        }
        .gel-23\/24\@xxl {
          width: 95.83333% !important;
        }
      }